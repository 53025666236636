// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SpinAnimation_animation17219329140341__Nqs\\+u {
  height: calc(100% + 4.1px);
  width: calc(100% - 34.9px);
  position: absolute;
  margin: 0 !important;
  top: -2.41px;
  right: 25.61px;
  bottom: -1.69px;
  left: 9.29px;
  box-shadow: -27px 21px 90px #b7920c, -1px 6px 0-1px rgba(255, 225, 119, 0.5);
  border-radius: var(--br-331xl);
  background: radial-gradient(
    50% 50%at 50% 50%,
    rgba(255, 225, 119, 0.25),
    rgba(126, 100, 6, 0.05)
  );
  border: 2px solid var(--color-khaki-200);
  box-sizing: border-box;
  transform: rotate(5.3deg);
  transform-origin: 0 0;
}
.SpinAnimation_spinAnimation__AzSJD {
  width: 131.2px;
  margin: 0 !important;
  bottom: 68px;
  left: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 4;
  position: fixed;
}
`, "",{"version":3,"sources":["webpack://./src/components/SpinAnimation.module.css"],"names":[],"mappings":"AAAA;EACE,0BAA0B;EAC1B,0BAA0B;EAC1B,kBAAkB;EAClB,oBAAoB;EACpB,YAAY;EACZ,cAAc;EACd,eAAe;EACf,YAAY;EACZ,4EAA4E;EAC5E,8BAA8B;EAC9B;;;;GAIC;EACD,wCAAwC;EACxC,sBAAsB;EACtB,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,cAAc;EACd,oBAAoB;EACpB,YAAY;EACZ,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,2BAA2B;EAC3B,UAAU;EACV,eAAe;AACjB","sourcesContent":[".animation17219329140341 {\n  height: calc(100% + 4.1px);\n  width: calc(100% - 34.9px);\n  position: absolute;\n  margin: 0 !important;\n  top: -2.41px;\n  right: 25.61px;\n  bottom: -1.69px;\n  left: 9.29px;\n  box-shadow: -27px 21px 90px #b7920c, -1px 6px 0-1px rgba(255, 225, 119, 0.5);\n  border-radius: var(--br-331xl);\n  background: radial-gradient(\n    50% 50%at 50% 50%,\n    rgba(255, 225, 119, 0.25),\n    rgba(126, 100, 6, 0.05)\n  );\n  border: 2px solid var(--color-khaki-200);\n  box-sizing: border-box;\n  transform: rotate(5.3deg);\n  transform-origin: 0 0;\n}\n.spinAnimation {\n  width: 131.2px;\n  margin: 0 !important;\n  bottom: 68px;\n  left: 0;\n  display: flex;\n  flex-direction: row;\n  align-items: flex-start;\n  justify-content: flex-start;\n  z-index: 4;\n  position: fixed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animation17219329140341": `SpinAnimation_animation17219329140341__Nqs+u`,
	"spinAnimation": `SpinAnimation_spinAnimation__AzSJD`
};
export default ___CSS_LOADER_EXPORT___;
