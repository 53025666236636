import React, {useState, useEffect} from 'react';
import PropTypes from "prop-types";
import axios from 'axios'; // Ensure axios is imported
import styles from "./RankItemDetails.module.css";

const RankItemDetails = ({className = ""}) => {
    const [leaders, setLeaders] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLeaders = async () => {
            try {
                const {data} = await axios.get('/api/users/leaderboard');
                const usersWithAvatars = await Promise.all(
                    data.slice(0, 3).map(async (user) => {
                        try {
                            const resAvatar = await axios.get(`/api/users/avatar/${user.userId}`, {
                                responseType: 'blob',
                            });
                            if (resAvatar.data) {
                                const objectUrl = URL.createObjectURL(resAvatar.data);
                                return {...user, avatar: objectUrl};
                            }
                        } catch (err) {
                            console.warn("Avatar error", err);
                        }
                        return user;
                    })
                );
                setLeaders(usersWithAvatars);
            } catch (error) {
                console.error('Error fetching leaderboard data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLeaders();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    const [firstLeader, secondLeader, thirdLeader] = leaders;

    return (
        <div className={[styles.rankItemDetails, className].join(" ")}>
            <div className={styles.rankNumberContainer}>
                <img
                    className={styles.rankNumberContainerChild}
                    alt=""
                    src="/rectangle-93.svg"
                />
                <h1 className={styles.h1}>۱</h1>
            </div>
            <div className={styles.lightBgParent}>
                <img className={styles.lightBgIcon} alt="" src="/lightbg5.svg"/>
                <img className={styles.icon} loading="lazy" alt="" src="/2.svg"/>
                {thirdLeader && (
                <img
                    className={styles.avatarIcon}
                    loading="lazy"
                    alt=""
                    src={thirdLeader.avatar}
                />
                    )}
                <img className={styles.frameChild} alt="" src="/rectangle-94.svg"/>
                {secondLeader && (
                    <img className={styles.avatarIcon1} alt="" src={secondLeader.avatar}/>
                )}
                <img
                    className={styles.frameItem}
                    loading="lazy"
                    alt=""
                    src="/rectangle-95.svg"
                />
                {firstLeader && (
                    <img
                        className={styles.avatarIcon2}
                        loading="lazy"
                        alt=""
                        src={firstLeader.avatar}
                    />
                )}
            </div>
            <div className={styles.rankDetails}>
                {secondLeader && (
                    <div className={styles.rankDetailsInner}>
                        <div className={styles.frameParent}>
                            <div className={styles.aroosakirooniDetailsWrapper}>
                                <div className={styles.aroosakirooniDetails}>
                                    <div className={styles.aroosakirooni}>{secondLeader.username}</div>
                                    <div className={styles.pointWrapper}>
                                        <div className={styles.point}>
                                            <img
                                                className={styles.iconcoinpoint}
                                                loading="lazy"
                                                alt=""
                                                src="/coin-dollar.svg"
                                            />
                                            <div className={styles.wrapper}>
                                                <div className={styles.div}>{secondLeader.coins}</div>
                                            </div>
                                            <div className={styles.wrapper}>
                                                <img
                                                    className={styles.fiRrCaretUpIcon}
                                                    loading="lazy"
                                                    alt=""
                                                    src="/firrcaretup.svg"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.rankTwoContainer}>
                                <div className={styles.rankTwoContainerChild}/>
                                <h1 className={styles.h11}>۲</h1>
                            </div>
                        </div>
                    </div>
                )}
                <div className={styles.pointContainer}>
                    <div className={styles.raanaAzadifar}>{firstLeader.username}</div>
                    <div className={styles.point1}>
                        <img
                            className={styles.iconcoinpoint}
                            loading="lazy"
                            alt=""
                            src="/coin-dollar.svg"
                        />
                        <div className={styles.wrapper}>
                            <div className={styles.div1}>{firstLeader.coins}</div>
                        </div>
                        <div className={styles.wrapper}>
                            <img
                                className={styles.fiRrCaretUpIcon}
                                loading="lazy"
                                alt=""
                                src="/firrcaretup-1.svg"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.raanaazadifarDetails}>
                <div className={styles.aroosakirooniDetails}>
                        <div className={styles.raanaAzadifar}>{thirdLeader.username}</div>
                        <div className={styles.pointFrame}>
                            <div className={styles.point2}>
                                <img
                                    className={styles.iconcoinpoint2}
                                    alt=""
                                    src="/coin-dollar.svg"
                                />
                                <div className={styles.wrapper}>
                                    <div className={styles.div2}>{thirdLeader.coins}</div>
                                </div>
                                <div className={styles.wrapper}>
                                    <img
                                        className={styles.fiRrCaretUpIcon}
                                        alt=""
                                        src="/firrcaretdown1.svg"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

RankItemDetails.propTypes = {
    className: PropTypes.string,
};

export default RankItemDetails;
