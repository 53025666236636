// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoDrArz_vectorIcon__O\\+fe4 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.LogoDrArz_drarznet__T3gB0 {
  position: absolute;
  top: 82.26%;
  left: 40.39%;
  letter-spacing: 0.49em;
  line-height: 143%;
  font-weight: 600;
  display: inline-block;
  min-width: 99px;
  z-index: 1;
}
.LogoDrArz_logoDrArz__N2ubP {
  height: 62px;
  flex: 1 1;
  position: relative;
  z-index: 3;
  text-align: left;
  font-size: var(--font-size-xs);
  color: var(--color-white);
  font-family: var(--font-vazirmatn-rd);
}
`, "",{"version":3,"sources":["webpack://./src/components/LogoDrArz.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,MAAM;EACN,QAAQ;EACR,SAAS;EACT,OAAO;EACP,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;EAChB,qBAAqB;EACrB,eAAe;EACf,UAAU;AACZ;AACA;EACE,YAAY;EACZ,SAAO;EACP,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,8BAA8B;EAC9B,yBAAyB;EACzB,qCAAqC;AACvC","sourcesContent":[".vectorIcon {\n  position: absolute;\n  height: 100%;\n  width: 100%;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  max-width: 100%;\n  overflow: hidden;\n  max-height: 100%;\n}\n.drarznet {\n  position: absolute;\n  top: 82.26%;\n  left: 40.39%;\n  letter-spacing: 0.49em;\n  line-height: 143%;\n  font-weight: 600;\n  display: inline-block;\n  min-width: 99px;\n  z-index: 1;\n}\n.logoDrArz {\n  height: 62px;\n  flex: 1;\n  position: relative;\n  z-index: 3;\n  text-align: left;\n  font-size: var(--font-size-xs);\n  color: var(--color-white);\n  font-family: var(--font-vazirmatn-rd);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vectorIcon": `LogoDrArz_vectorIcon__O+fe4`,
	"drarznet": `LogoDrArz_drarznet__T3gB0`,
	"logoDrArz": `LogoDrArz_logoDrArz__N2ubP`
};
export default ___CSS_LOADER_EXPORT___;
