import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import JDate from 'jalali-date';
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import styles from './Friendslist.module.css';

const Friendslist = ({
                         className = '',
                         propBorderRadius,
                         symbol,
                         propBorderRadius1,
                     }) => {
    const [orders, setOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [count, setCount] = useState(0);
    const userId = localStorage.getItem("userId");


    // Fetch orders with pagination
    const fetchOrders = async (page) => {
        try {
            const {data} = await axios.get(`/api/orders/${userId}&page=${page}`);
            if (data.orders.length > 0) {
                setCount(data.count)
                setOrders((prevOrders) => [...prevOrders, ...data.orders]);
                if (orders.length === 0 || orders.length < 10) {
                    setHasMore(false);
                }
            } else {
                setHasMore(false); // Stop fetching if no more data
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    };

    // Initial data fetch
    useEffect(() => {
        fetchOrders(1);
    }, []);

    // Load more orders when scrolled to the bottom
    const loadMoreOrders = () => {
        setPage((prevPage) => {
            const nextPage = prevPage + 1;
            fetchOrders(nextPage);
            return nextPage;
        });
    };

    return (
        <>
        <div className={styles.historyHeader}>
            <div className={styles.buttonCircle}>
                <div className={styles.numbers}>
                    <div className={styles.div2}>{count} مورد یافت شد</div>
                </div>
            </div>
            <div className={styles.historyList}>
                <div className={styles.historyListHeader}>
                    <div className={styles.div3}>تاریخچه فروش</div>
                    <div className={styles.historyIcon}>
                        <img
                            className={styles.iconshistory}
                            loading="lazy"
                            alt=""
                            src="/iconshistory.svg"
                        />
                    </div>
                </div>
            </div>
        </div>

    <InfiniteScroll
        dataLength={orders.length}
        next={loadMoreOrders}
        hasMore={hasMore}
        className={styles.scrollHistory}
        scrollableTarget="scrollHistory"
        loader={<div>در حال بارگذاری</div>}
        endMessage={<p style={{textAlign: 'center'}}><b>مورد بیشتری یافت نشد</b></p>}
    >
        {orders.map((order, index) => (
            <div className={[styles.friendslist, className].join(' ')}>
                {/* Render your order details here using the existing styles */}
                <div className={styles.content}>
                    <div className={styles.div}>
                        <span className={styles.span}>{order.price} </span>
                                <span className={styles.span1}>ریال</span>
                            </div>
                            <div className={styles.numbers}>
                                <div className={styles.div1}>{order.status === 0 ? 'معلق' : order.status === 1 ? 'موفق' : 'رد شده'}</div>
                            </div>
                        </div>
                        <div className={styles.userInfo}>
                            <div className={styles.nameid}>
                                <div className={styles.ton}>
                                    {order.symbol}
                                </div>
                                <div className={styles.separator}>
                                    <span className={styles.span}>{order.amount}</span>
                                </div>
                                <div className={styles.div2}>{order.type === 'Sell' ? 'فروش' : 'خرید'}</div>
                            </div>
                            <div className={styles.bankInfo}>
                                <div className={styles.div3}>{order.unitRialPrice}</div>
                                <div className={styles.dateSeparator}>.</div>
                                <div className={styles.div4}>{new JDate(new Date(order.createdAt)).format('dddd DD MMMM YYYY')}</div>
                            </div>
                        </div>
                        <div className={styles.logo} style={{borderRadius: propBorderRadius}}>
                            <img
                                className={styles.image3Icon}
                                loading="lazy"
                                alt=""
                                src={`/images/symbol/${symbol}.png`}
                                style={{borderRadius: propBorderRadius1}}
                            />
                        </div>
                    </div>
                ))}
            </InfiniteScroll>
        </>
    );
};

Friendslist.propTypes = {
    className: PropTypes.string,
    prop: PropTypes.string,
    tON: PropTypes.string,
    prop1: PropTypes.string,
    image3: PropTypes.string,
    propMinWidth: PropTypes.any,
    propWidth: PropTypes.any,
    propColor: PropTypes.any,
    propMinWidth1: PropTypes.any,
    propWidth1: PropTypes.any,
    propMinWidth2: PropTypes.any,
    propFlex: PropTypes.any,
    propBorderRadius: PropTypes.any,
    propBorderRadius1: PropTypes.any,
};

export default Friendslist;
