import React, {useState, useEffect, useCallback, useRef} from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import MainNavigation from "../components/MainNavigation";
import styles from "./Home.module.css";
import AnimationIconMarket from "../components/AnimationIconMarket";
import LogoDrArz from "../components/LogoDrArz";

const Home = ({ className = "" }) => {
    const userId = localStorage.getItem("userId");
    const [coins, setCoins] = useState(0);
    const [username, setUsername] = useState("Unknown");
    const [avatar, setAvatar] = useState("");
    const [level, setLevel] = useState(1);
    const [loading, setLoading] = useState(true);
    const [lastAppUse, setLastAppUse] = useState(null);

    const [energy, setEnergy] = useState(0);

    const [bot, setBot] = useState(true);
    const [energyLevel, setEnergyLevel] = useState(1);
    const [tapLevel, setTapLevel] = useState(1);
    const [speedLevel, setSpeedLevel] = useState(1);
    const [lastTapUse, setLastTapUse] = useState(null);
    // const { state, open, close } = useTonConnectModal();
    const [plusOnes, setPlusOnes] = useState([]);
    const [lastTap, setLastTap] = useState(null);
    const [turbo, setTurbo] = useState(false);
    const updateTimeoutRef = useRef(null);

    const [clicks, setClicks] = useState([]);

    const navigate = useNavigate();

    const onIdrankContainerClick = useCallback(() => {
        navigate("/profile");
    }, [navigate]);

    const onRankingContainerClick = useCallback(() => {
        navigate("/ranking");
    }, [navigate]);

    const onMarketContainerClick = useCallback(() => {
        navigate("/marketmain");
    }, [navigate]);

    useEffect(() => {
        const interval = setInterval(() => {
            const maxEnergy = energyLevel * 500;
            setEnergy(prevEnergy => Math.min(prevEnergy + speedLevel, maxEnergy));
        }, 1000);

        return () => clearInterval(interval);
    }, [speedLevel, energyLevel]);

    const handleCardClick = (e: React.TouchEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (window.Telegram && window.Telegram.WebApp) {
            window.Telegram.WebApp.HapticFeedback.impactOccurred('medium');
        }

        // Iterate over each touch point
        Array.from(e.touches).map((touch) => {
            const card = e.currentTarget;
            const rect = card.getBoundingClientRect();
            const x = touch.clientX - rect.left - rect.width / 2;
            const y = touch.clientY - rect.top - rect.height / 2;

            handleTap(touch.pageX, touch.pageY);

            // Apply the transformation for each touch point
            card.style.transform = `perspective(1000px) rotateX(${-y / 10}deg) rotateY(${x / 10}deg)`;
            setTimeout(() => {
                card.style.transform = '';
            }, 100);
        });
    };

    const handleTap = (x: number, y: number) => {
        if (energy > tapLevel) {
            setClicks((prevClicks) => [
                ...prevClicks,
                {id: Date.now() + Math.random(), x, y},
            ]);

            if (turbo) {
                setCoins((prevCoins) => prevCoins + tapLevel * 5);
            } else {
                setEnergy((prevEnergy) => Math.max(0, prevEnergy - tapLevel));
                setCoins((prevCoins) => prevCoins + tapLevel);
            }

            setLastTap(Date.now());

            if (updateTimeoutRef.current) {
                clearTimeout(updateTimeoutRef.current);
            }
            updateTimeoutRef.current = setTimeout(saveTapData, 1000); // Schedule a new update 1 second after the last tap
        }
    };


    const handleAnimationEnd = (id: number) => {
        setClicks((prevClicks) => prevClicks.filter(click => click.id !== id));
    };

    const saveTapData = async () => {
        try {
            await axios.put('/api/users', {userId, coins, energy});
            console.log('User data updated successfully');
        } catch (error) {
            console.error('Failed to update user data', error);
        }
    };

    useEffect(() => {
        const fetchUserData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.BackButton.hide();
            }
            try {
                const response = await axios.get(`/api/users/${userId}`);
                if (response.data) {

                    setCoins(response.data.coins);
                    setUsername(response.data.username != null ? response.data.username : response.data.first_name);
                    setLevel(response.data.level);
                    setLastAppUse(response.data.lastTapUse);
                }

                const resAvatar = await axios.get(`/api/users/avatar/${userId}`, {
                    responseType: 'blob',
                });
                if (resAvatar.data) {
                    const objectUrl = URL.createObjectURL(resAvatar.data);
                    setAvatar(objectUrl);
                }
            } catch (error) {
                console.error('Failed to fetch user data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);
  return (
      <div className={styles.home}>
          <div className={[styles.marketRankWrapper, className].join(" ")}>
              <div className={styles.marketRank}>
                  <div className={styles.market} onClick={onMarketContainerClick}>
                      <AnimationIconMarket/>
                      <b className={styles.b}>بازار ارزها</b>
                  </div>
                  <div className={styles.rank}>
                      <div className={styles.idrank} onClick={onIdrankContainerClick}>
                          <h3 className={styles.milapahlavnian}>@{username}</h3>
                          <div className={styles.rankValueContainer}>
                              <div className={styles.rankValue}>
                                  <b className={styles.b1}>{coins}</b>
                                  <div className={styles.div}>امتیاز شما</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className={styles.ranking} onClick={onRankingContainerClick}>
                      <div className={styles.icontrophyWrapper}>
                          <img
                              className={styles.icontrophy}
                              loading="lazy"
                              alt=""
                              src="/icontrophy.svg"
                          />
                      </div>
                      <b className={styles.b2}>برترینها</b>
                  </div>
              </div>
          </div>
          <main className={styles.content}>
              <section className={styles.conteiner}>
                  <img className={styles.lightBg01Icon} alt="" src="/lightbg01.svg"/>
                  <img className={styles.shapeBgIcon} alt="" src="/shapebg@2x.png"/>
                  <img
                      className={styles.doctorarzMen01Icon}
                      onTouchStart={handleCardClick}
                      alt=""
                      src="/doctorarzmen01@2x.png"
                  />
                  <div className={[styles.doctorarzInfoWrapper, className].join(" ")}>
                      <div className={styles.doctorarzInfo}>
                          <div className={styles.doctorarzDescription}>
                              <div className={styles.doctorarzLogo}>
                                  <LogoDrArz/>
                              </div>
                              <h2 className={styles.h2}>اولین صرافی هوشمند ارزدیجیتال در ایران</h2>
                          </div>
                          <div className={styles.video}>
                              <div className={styles.watchVideo}>
                                  <div className={styles.getPoint}>
                                      <img
                                          className={styles.iconcoinpoint}
                                          loading="lazy"
                                          alt=""
                                          src="/iconcoinpoint.svg"
                                      />
                                      <div className={styles.points}>
                                          <div className={styles.div}>+100</div>
                                      </div>
                                  </div>
                                  <div className={styles.videoGuide}>
                                      <b className={styles.b}>راهنمای کار با ربات</b>
                                  </div>
                                  <div className={styles.playButton}>
                                      <img
                                          className={styles.hiconBoldPlayCircle}
                                          loading="lazy"
                                          alt=""
                                          src="/hicon--bold--play-circle.svg"
                                      />
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <img className={styles.groupIcon} alt=""/>
                  {clicks.map((click) => (
                      <div
                          key={click.id}
                          className="absolute text-5xl font-bold opacity-0 text-white pointer-events-none"
                          style={{
                              top: `${click.y - 20}px`,
                              left: `${click.x - 28}px`,
                              animation: `float 1s ease-out`,
                              zIndex: '1000',
                          }}
                          onAnimationEnd={() => handleAnimationEnd(click.id)}
                      >
                          +{turbo ? tapLevel * 5 : tapLevel}
                      </div>
                  ))}
                  <MainNavigation/>
              </section>
          </main>
      </div>
  );
};

export default Home;
