// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.spinner svg {
    width: 80px;
    height: 80px;
    animation: rotate 2s linear infinite;
}

.path {
    stroke: url(#gradient);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Loading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,oCAAoC;AACxC;;AAEA;IACI,sBAAsB;IACtB,qBAAqB;IACrB,yCAAyC;AAC7C;;AAEA;IACI;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,wBAAwB;QACxB,oBAAoB;IACxB;IACA;QACI,yBAAyB;QACzB,sBAAsB;IAC1B;IACA;QACI,yBAAyB;QACzB,uBAAuB;IAC3B;AACJ","sourcesContent":[".spinner {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100%;\n}\n\n.spinner svg {\n    width: 80px;\n    height: 80px;\n    animation: rotate 2s linear infinite;\n}\n\n.path {\n    stroke: url(#gradient);\n    stroke-linecap: round;\n    animation: dash 1.5s ease-in-out infinite;\n}\n\n@keyframes rotate {\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n@keyframes dash {\n    0% {\n        stroke-dasharray: 1, 150;\n        stroke-dashoffset: 0;\n    }\n    50% {\n        stroke-dasharray: 90, 150;\n        stroke-dashoffset: -35;\n    }\n    100% {\n        stroke-dasharray: 90, 150;\n        stroke-dashoffset: -124;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
