import PropTypes from "prop-types";
import styles from "./Press.module.css";

const Press = ({ className = "" }) => {
  return (
    <div className={[styles.press, className].join(" ")}>
      <div className={styles.parent}>
        <b className={styles.b}>دکتر ارز در رسانه ها</b>
        <div className={styles.div}>
          پس از شروع فعالیت دکتر ارز، رسانه ها و خبرگزاری های معتبر به خدمات ما
          پرداخت، در ادامه به تعدادی از آنها اشاره کرده ایم.
        </div>
      </div>
      <div className={styles.logoWrapper}>
        <div className={styles.logo}>
          <div className={styles.rona}>
            <img className={styles.roknaIcon} loading="lazy" alt="" />
          </div>
          <div className={styles.farsnews}>
            <img className={styles.farsnewsIcon} loading="lazy" alt="" />
          </div>
          <div className={styles.rahpardakht}>
            <img className={styles.rahpardakhtIcon} loading="lazy" alt="" />
          </div>
          <div className={styles.farsnews}>
            <img className={styles.zoomitIcon} loading="lazy" alt="" />
          </div>
          <div className={styles.digiato}>
            <img className={styles.digiatoIcon} alt="" />
          </div>
          <div className={styles.mihanblockchain}>
            <img
              className={styles.mihanblockchainIcon}
              loading="lazy"
              alt=""
              src="/mihanblockchain.svg"
            />
          </div>
          <div className={styles.tebyan}>
            <img
              className={styles.tebyanIcon}
              loading="lazy"
              alt=""
              src="/tebyan.svg"
            />
          </div>
          <div className={styles.farsnews}>
            <img
              className={styles.mihanblockchainIcon}
              loading="lazy"
              alt=""
              src="/irna.svg"
            />
          </div>
          <div className={styles.tabnak}>
            <img
              className={styles.tabnakIcon}
              loading="lazy"
              alt=""
              src="/tabnak.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Press.propTypes = {
  className: PropTypes.string,
};

export default Press;
