import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {toast} from 'react-toastify';
import OTPInput from 'react-otp-input';
import FrameComponent3 from "../components/FrameComponent3";
import FrameComponent2 from "../components/FrameComponent2";
import styles from "./Profile.module.css";
import MainNavigation from "../components/MainNavigation";
import Loading from "../components/Loading";

const Profile = ({className = ""}) => {
    const userId = localStorage.getItem("userId");
    const [coins, setCoins] = useState(0);
    const [username, setUsername] = useState("Unknown");
    const [firstname, setFirstname] = useState("نام کامل");
    const [lastname, setLastname] = useState("");
    const [idCard, setIdCard] = useState("کد ملی");
    const [isVerified, setIsVerified] = useState(false);
    const [isNameVerified, setIsNameVerified] = useState(false);
    const [isIdVerified, setIsIdVerified] = useState(false);
    const [isMobileVerified, setIsMobileVerified] = useState(false);
    const [avatar, setAvatar] = useState("");
    const [level, setLevel] = useState(1);
    const [mobile, setMobile] = useState("9123456789");
    const [verifyCode, setVerifyCode] = useState(null);
    const [loading, setLoading] = useState(true);
    const [lastAppUse, setLastAppUse] = useState(null);
    const [timer, setTimer] = useState(null);
    const [otp, setOtp] = useState("");

    const navigate = useNavigate();

    const onIdrankContainerClick = useCallback(() => {
        navigate("/profile");
    }, [navigate]);

    const onRankingContainerClick = useCallback(() => {
        navigate("/ranking");
    }, [navigate]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.BackButton.show();
                window.Telegram.WebApp.BackButton.onClick(() => {
                    window.history.back();
                });
            }

            try {
                const response = await axios.get(`/api/users/${userId}`);
                console.log('resUser', response)
                if (response.data) {
                    setFirstname(response.data.first_name);
                    setLastname(response.data.last_name);
                    setCoins(response.data.coins);
                    setUsername(response.data.first_name != null ? response.data.first_name : response.data.username);
                    setLevel(response.data.level);
                    setLastAppUse(response.data.lastTapUse);
                    setIsVerified(response.data.isVerified);
                    setIsIdVerified(response.data.isIdVerified);
                    setIsNameVerified(response.data.isNameVerified);
                    setMobile(response.data.mobile);
                    setIsMobileVerified(response.data.isMobileVerified);
                    setIdCard(response.data.idCard);
                }

                const resAvatar = await axios.get(`/api/users/avatar/${userId}`, {
                    responseType: 'blob',
                });
                if (resAvatar.data) {
                    const objectUrl = URL.createObjectURL(resAvatar.data);
                    setAvatar(objectUrl);
                }
            } catch (error) {
                console.error('Failed to fetch user data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    const convertToEnglishDigits = (input) => {
        const arabicToEnglishMap = {
            '٠': '0', // Arabic 0
            '١': '1', // Arabic 1
            '٢': '2', // Arabic 2
            '٣': '3', // Arabic 3
            '٤': '4', // Arabic 4
            '٥': '5', // Arabic 5
            '٦': '6', // Arabic 6
            '٧': '7', // Arabic 7
            '٨': '8', // Arabic 8
            '٩': '9', // Arabic 9
            '۰': '0', // Persian 0
            '۱': '1', // Persian 1
            '۲': '2', // Persian 2
            '۳': '3', // Persian 3
            '۴': '4', // Persian 4
            '۵': '5', // Persian 5
            '۶': '6', // Persian 6
            '۷': '7', // Persian 7
            '۸': '8', // Persian 8
            '۹': '9', // Persian 9
        };

        return input.split('').map(char => arabicToEnglishMap[char] || char).join('');
    };

    const handleSaveData = async (fieldType) => {
        try {
            let requestData = {};
            if (fieldType === 'name') {
                const firstname = document.getElementById('firstname').value;
                requestData = {
                    userId: userId,
                    first_name: firstname,
                };
            } else if (fieldType === 'idCard') {
                const idCard = document.getElementById('idCard').value;
                let idCardNew = convertToEnglishDigits(idCard);
                if (idCardNew.length < 10) {
                    toast.error('کد ملی نامعتبر است!');
                }
                requestData = {
                    userId: userId,
                    idCard: idCardNew,
                };
            }

            const response = await axios.put('/api/users', requestData);

            if (response.status === 200) {
                if (fieldType === 'name') {
                    setIsNameVerified(true);
                    toast.success('نام با موفقیت ویرایش شد.');
                } else if (fieldType === 'idCard') {
                    setIsIdVerified(true);
                    toast.success('کد ملی با موفقیت ویرایش شد.');
                }
            }
        } catch (error) {
            console.error(`Failed to save ${fieldType}`, error);
            // You might want to display an error message to the user here
        }
    };

    const handleSendCode = async () => {

        try {
            let requestData = {};
            const reqMobile = document.getElementById('mobile').value;
            const mobileValue = reqMobile ? reqMobile : mobile
            requestData = {
                userId: userId,
                mobile: mobileValue,
            };

            const response = await axios.put('/api/users', requestData);
            console.log('reqData', requestData)
            if (response.status === 200) {
                const response = await axios.post('/api/notify', {userId, mobile: mobileValue});
                if (response.data) {
                    setVerifyCode(response.data.verifyCode); // Save the code from backend
                    setMobile(response.data.mobile);
                    toast.success('کد تایید ارسال شد.');
                    setTimer(60); // Set the timer to 60 seconds
                }
            }
        } catch (error) {
            console.error(`Failed to save mobile`, error);
            toast.error('ارسال کد ناموفق بود.');
            // You might want to display an error message to the user here
        }
    };

    // Handle OTP input change
    const handleOtpChange = (otp) => {
        setOtp(otp);
    };

    // Handle Verify button click
    const handleVerifyClick = async () => {
        // Validate OTP when the user clicks the "تایید" button
        if (otp.length === 4) {
            if (otp === verifyCode) {
                setIsMobileVerified(true);
                try {
                    let requestData = {};
                    requestData = {
                        userId: userId,
                        isMobileVerified: true,
                        first_name: firstname,
                        idCard: idCard
                    };
                    const response = await axios.put('/api/users', requestData);
                    toast.success('شماره موبایل با موفقیت تایید شد.');
                } catch (error) {
                    console.error(`Failed to save mobile`, error);
                    toast.success('تایید شماره موبایل انجام نشد.');
                    // You might want to display an error message to the user here
                }
            } else {
                toast.error('کد تایید اشتباه است.');
            }
        } else {
            toast.error('لطفا کد تایید ۴ رقمی را وارد کنید.');
        }
    };

    useEffect(() => {
        if (timer !== null && timer > 0) {
            const countdown = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);

            return () => clearInterval(countdown); // Clean up the interval when the component unmounts or timer changes
        } else if (timer === 0) {
            // When timer hits zero, close the modal or perform any other action you want
            closeModal();
            window.scrollTo({top: 0, behavior: 'smooth'});
            toast.error('زمان وارد کردن کد به پایان رسید.');
        }
    }, [timer]);

    return (
        <div className={styles.profile}>
            {loading ? (
                <Loading/>
            ) : (
                <>
                    <img className={styles.lightBg01Icon} alt="" src="/lightbg011.svg"/>
                    <div className={styles.conteiner}>
                        <div className={[styles.conteinerInner, className].join(" verifyPersonal")}>
                            <div className={styles.frameParent}>
                                <div className={styles.getPointParent}>
                                    <div className={styles.getPoint}>
                                        <div className={styles.frameGroup}>
                                            <div className={styles.iconcoinpointParent}>
                                                {isVerified && (
                                                    <div className={styles.iconscheck1Wrapper}>
                                                        <img
                                                            className={styles.iconscheck1}
                                                            loading="lazy"
                                                            alt=""
                                                            src="/iconscheck-1.svg"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className={styles.wrapper}>
                                                <div
                                                    className={styles.div}>{isVerified ? "تایید شده" : "تایید نشده"}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.frameWrapper}>
                                        <div className={styles.parent}>
                                            <b className={styles.b}>اطلاعات کاربری</b>
                                            <div className={styles.iconidcardWrapper}>
                                                <img
                                                    className={styles.iconidcard}
                                                    loading="lazy"
                                                    alt=""
                                                    src="/iconidcard.svg"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.div1}>
                                    <div className={styles.fullname}>
                                        <div className={styles.inputtext}>
                                            {isVerified ? (
                                                <img
                                                    className={styles.checkCircle1Icon}
                                                    loading="lazy"
                                                    alt="Verify Name"
                                                    src="/checkcircle-1.svg"
                                                />
                                            ) : isNameVerified ? (
                                                <div className={styles.buttonsend1}>
                                                    <div className={styles.div3}>در حال بررسی</div>
                                                </div>
                                            ) : (
                                                <div className={styles.buttonsend1}
                                                     onClick={() => handleSaveData('name')}>
                                                    <div className={styles.div3}>ثبت</div>
                                                </div>
                                            )}
                                            <input
                                                id="firstname"
                                                name="firstname"
                                                className={styles.inputField}
                                                type="text"
                                                placeholder={`${firstname}`}
                                                readOnly={isVerified || isNameVerified}
                                            />
                                            <b className={styles.b1}>نام کامل</b>
                                        </div>
                                    </div>
                                    <div className={styles.fullname}>
                                        <div className={styles.inputtext}>
                                            {isVerified ? (
                                                <img
                                                    className={styles.checkCircle1Icon}
                                                    loading="lazy"
                                                    alt="Verify Name"
                                                    src="/checkcircle-1.svg"
                                                />
                                            ) : isIdVerified ? (
                                                <div className={styles.buttonsend1}>
                                                    <div className={styles.div3}>در حال بررسی</div>
                                                </div>
                                            ) : (
                                                <div className={styles.buttonsend1}
                                                     onClick={() => handleSaveData('idCard')}>
                                                    <div className={styles.div3}>ثبت</div>
                                                </div>
                                            )}
                                            <input
                                                id="idCard"
                                                minLength="10"
                                                maxLength="10"
                                                name="idCard"
                                                className={styles.inputField}
                                                type="text"
                                                placeholder={`${idCard ? idCard : ""}`}
                                                readOnly={isVerified || isIdVerified}
                                            />
                                            <b className={styles.b1}>کد ملی</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={[styles.vectorParent, className].join(" verifyMobile")}>
                            <img
                                className={styles.frameChild}
                                loading="lazy"
                                alt=""
                                src="/line-1.svg"
                            />
                            <div className={styles.mobileVerificationWrapper}>
                                <div className={styles.mobileVerification}>
                                    <div className={styles.verificationMessage}>
                                        <div className={styles.div}>
                                            لطفا با دریافت کد تایید و وارد کردن آن شماره را وریفای کنید
                                        </div>
                                        <b className={styles.b}>شماره همراه</b>
                                    </div>
                                    <div className={styles.verifyMobile}>
                                        <div className={styles.div1}>
                                            <div className={styles.nomber}>

                                                <div className={styles.inputmobile}>
                                                    <div className={styles.buttonsend1}
                                                         onClick={!isMobileVerified && !timer > 0 ? handleSendCode : null}>
                                                        <div className={styles.div3}>
                                                            {isMobileVerified
                                                                ? '✓' // اگر موبایل احراز شده باشد
                                                                : timer > 0
                                                                    ? `${timer}` // اگر تایمر در حال اجرا باشد
                                                                    : 'ارسال کد' // اگر تایمر تمام شده باشد و موبایل احراز نشده باشد
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className={styles.frameParent}>
                                                        <div className={styles.fiRrCaretDownParent}>

                                                            <div className={styles.div4}>+۹۸</div>
                                                        </div>
                                                        <input
                                                            id="mobile"
                                                            name="mobile"
                                                            className={styles.mobile}
                                                            type="text"
                                                            placeholder={mobile}
                                                            readOnly={isMobileVerified}
                                                        />
                                                        {/*<div className={styles.div5}>۰۹۱۲ ۵۹۵ ۰۰ ۵۳</div>*/}
                                                        <img
                                                            className={styles.dangerTriangleIcon}
                                                            loading="lazy"
                                                            alt=""
                                                            src="/danger-triangle.svg"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {!isMobileVerified && (
                                                <div className={styles.nomber1}>
                                                    <div className={styles.buttonsend2}>
                                                        <div onClick={handleVerifyClick} className={styles.div6}>تایید
                                                        </div>
                                                    </div>
                                                    <div className={styles.inputmobile1}>
                                                        <div className={styles.codeInputsWrapper}>
                                                            <div className={styles.otpWrapper}>
                                                                <OTPInput
                                                                    value={otp}
                                                                    onChange={handleOtpChange}
                                                                    numInputs={4}
                                                                    renderInput={(props) => <input {...props} />}
                                                                    isDisabled={isMobileVerified}
                                                                    inputType="tel"
                                                                    placeholder="0"
                                                                    inputStyle={{
                                                                        width: "1rem",
                                                                        height: "1rem",
                                                                        margin: "0 0.5rem",
                                                                        fontSize: "1rem",
                                                                        textAlign: "center",
                                                                        border: "auto"
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img className={styles.dangerTriangleIcon} alt=""/>
                        <FrameComponent3 userId={userId}/>
                        <div className={styles.conteinerChild}/>
                    </div>
                    <FrameComponent2 coins={coins} firstname={firstname} lastname={lastname} username={username}
                                     level={level}
                                     avatar={avatar}/>
                </>
            )}
            <MainNavigation/>
        </div>
    );
};

export default Profile;
