// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PortalPopup_portalPopupOverlay__NP6jp {
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/PortalPopup.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,QAAQ;AACV","sourcesContent":[".portalPopupOverlay {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  inset: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portalPopupOverlay": `PortalPopup_portalPopupOverlay__NP6jp`
};
export default ___CSS_LOADER_EXPORT___;
