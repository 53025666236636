import React, {useEffect, useState} from 'react';
import { THEME, TonConnectUIProvider } from '@tonconnect/ui-react';
import {
    Routes,
    Route,
    useNavigationType,
    useLocation,
} from "react-router-dom";
import axios from 'axios';
import Home from "./pages/Home";
import Market from "./pages/Market";
import Profile from "./pages/Profile";
import Support from "./pages/Support";
import AboutUS from "./pages/AboutUS";
import Earn from "./pages/Earn";
import Task from "./pages/Task";
import MarketMain from "./pages/MarketMain";
import Ranking from "./pages/Ranking";
import SpinWheel from "./pages/SpinWheel";
import {ToastContainer, Slide} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import eruda from 'eruda';

axios.defaults.baseURL = 'https://drarzserver.sarafiton.com:5001';

function App() {
    eruda.init();
    const [userId, setUserId] = useState(null);
    const action = useNavigationType();
    const location = useLocation();
    const pathname = location.pathname;

    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.expand();
        window.Telegram.WebApp.MainButton.hide();
    }

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp.initDataUnsafe) {
            window.Telegram.WebApp.setHeaderColor("#000000");
            window.Telegram.WebApp.setBackgroundColor("#000000");
            window.Telegram.WebApp.disableVerticalSwipes()

            const initData = window.Telegram.WebApp.initDataUnsafe;
            console.log("telegramId", initData.user)
            if (initData && initData.user && initData.user.id) {
                setUserId(initData.user.id);
                localStorage.setItem("userId", initData.user.id);
            } else {
                setUserId(76828379);
                localStorage.setItem("userId", 76828379);
            }
        }

        if (action !== "POP") {
            window.scrollTo(0, 0);
        }

        const handleTouchMove = (e) => {
            if (!e.target.closest('.allow-touch')) {
                e.preventDefault();
            }
        };

        document.addEventListener('touchmove', handleTouchMove, {passive: false});
    }, [action, pathname]);

    useEffect(() => {
        let title = "";
        let metaDescription = "";

        switch (pathname) {
            case "/":
                title = "";
                metaDescription = "";
                break;
            case "/":
                title = "";
                metaDescription = "";
                break;
            case "/profile":
                title = "";
                metaDescription = "";
                break;
            case "/support":
                title = "";
                metaDescription = "";
                break;
            case "/about-us":
                title = "";
                metaDescription = "";
                break;
            case "/earn":
                title = "";
                metaDescription = "";
                break;
            case "/marketmain":
                title = "";
                metaDescription = "";
                break;
            case "/ranking":
                title = "";
                metaDescription = "";
                break;
            case "/spinwheel":
                title = "";
                metaDescription = "";
                break;
            case "/task":
                title = "";
                metaDescription = "";
                break;
        }

        if (title) {
            document.title = title;
        }

        if (metaDescription) {
            const metaDescriptionTag = document.querySelector(
                'head > meta[name="description"]'
            );
            if (metaDescriptionTag) {
                metaDescriptionTag.content = metaDescription;
            }
        }
    }, [pathname]);

    return (
        <>
            <TonConnectUIProvider
                manifestUrl="https://drarz.sarafiton.com/tonconnect-manifest.json"
                uiPreferences={{theme: THEME.DARK}}
                actionsConfiguration={{
                    twaReturnUrl: 'https://t.me/hicoinclub_bot/start'
                }}
            >
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={true}
                    pauseOnFocusLoss={false}
                    draggable
                    pauseOnHover
                    theme="dark"
                    transition={Slide}/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/market/:symbol" element={<Market/>}/>
                    <Route path="/profile" element={<Profile/>}/>
                    <Route path="/support" element={<Support/>}/>
                    <Route path="/about-us" element={<AboutUS/>}/>
                    <Route path="/earn" element={<Earn/>}/>
                    <Route path="/marketmain" element={<MarketMain/>}/>
                    <Route path="/ranking" element={<Ranking/>}/>
                    <Route path="/spinwheel" element={<SpinWheel/>}/>
                    <Route path="/task" element={<Task/>}/>
                </Routes>
            </TonConnectUIProvider>
        </>
    );
}

export default App;
