import PropTypes from "prop-types";
import styles from "./SpinAnimation.module.css";

const SpinAnimation = ({ className = "" }) => {
  return (
      // <div className={[styles.spinAnimation, className].join(" ")}>
          <img
              className={[styles.spinAnimation, className].join(" ")}
              alt=""
              src="/Spin_Animation.png"
          />
      // </div>
  );
};

SpinAnimation.propTypes = {
    className: PropTypes.string,
};

export default SpinAnimation;
