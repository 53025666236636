import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./MainNavigation.module.css";

const MainNavigation = ({ className = "" }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onAboutUsContainerClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onSupportContainerClick = useCallback(() => {
    navigate("/support");
  }, [navigate]);

  const onEarnContainerClick = useCallback(() => {
    navigate("/earn");
  }, [navigate]);

  const onProfileContainerClick = useCallback(() => {
    navigate("/profile");
  }, [navigate]);

  const onHomeContainerClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
      <footer className={[styles.mainNavigation, className].join(" ")}>
        <div className={styles.activeOnMenu} />
        <div className={styles.items}>
          <div
              className={[
                styles.aboutUs,
                location.pathname === "/about-us" ? styles.active : "",
              ].join(" ")}
              onClick={onAboutUsContainerClick}
          >
            <div className={styles.menuIcons}>
              <img
                  className={styles.iconinformation}
                  loading="lazy"
                  alt=""
                  src="/iconinformation.svg"
              />
            </div>
            <div className={styles.div}>درباره ما</div>
          </div>
          <div
              className={[
                styles.aboutUs,
                location.pathname === "/support" ? styles.active : "",
              ].join(" ")}
              onClick={onSupportContainerClick}
          >
            <div className={styles.iconsupportWrapper}>
              <img
                  className={styles.iconinformation}
                  loading="lazy"
                  alt=""
                  src="/iconsupport.svg"
              />
            </div>
            <div className={styles.div1}>پشتیبانی</div>
          </div>
          <div
              className={[
                styles.earn,
                location.pathname === "/earn" ? styles.active : "",
              ].join(" ")}
              onClick={onEarnContainerClick}
          >
            <div className={styles.iconcoinWrapper}>
              <img
                  className={styles.iconinformation}
                  loading="lazy"
                  alt=""
                  src="/iconcoin.svg"
              />
            </div>
            <div className={styles.div2}>کسب درآمد</div>
          </div>
          <div
              className={[
                styles.profile,
                location.pathname === "/profile" ? styles.active : "",
              ].join(" ")}
              onClick={onProfileContainerClick}
          >
            <div className={styles.iconprofileWrapper}>
              <img
                  className={styles.iconinformation}
                  loading="lazy"
                  alt=""
                  src="/iconprofile.svg"
              />
            </div>
            <div className={styles.div3}>پروفایل</div>
          </div>
          <div
              className={[
                styles.home,
                location.pathname === "/" ? styles.active : "",
              ].join(" ")}
              onClick={onHomeContainerClick}
          >
            <div className={styles.iconhomeWrapper}>
              <img
                  className={styles.iconinformation}
                  loading="lazy"
                  alt=""
                  src="/iconhome.svg"
              />
            </div>
            <div className={styles.div4}>خانه</div>
          </div>
        </div>
      </footer>
  );
};

MainNavigation.propTypes = {
  className: PropTypes.string,
};

export default MainNavigation;
