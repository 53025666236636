import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./Invoice.module.css";
import { toast } from 'react-toastify';
import { useTonConnectUI, SendTransactionRequest, useTonWallet } from '@tonconnect/ui-react';
import { beginCell, Address, toNano } from 'ton-core';
import logoDrArz from "./LogoDrArz";
import axios from 'axios';

const Invoice = ({ className = "", onClose, price, rialPrice, symbol, name, icon, amount, orderId }) => {
  // State to track whether the checkbox is checked
  const [isChecked, setIsChecked] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const mainWallet = "UQDWm-3w_f0MZF8IrdygE-B_AS2yx5BCfWSu56M-EsiVq9m0";

  // Handler for checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // Function to send a transaction using TON Connect
  const sendTransaction = async () => {
    try {
      // Check if the symbol is TON or a Jetton (like DOGS)
      if (symbol.toLowerCase() === "ton") {
        // Original code for sending TON
        const transaction = {
          validUntil: Math.floor(Date.now() / 1000) + 600, // 10 minutes from now
          messages: [
            {
              address: mainWallet, // Replace with the receiver's address
              amount: (amount * 1e9).toString(), // Convert to nanoton
            },
          ],
        };

        // Send TON transaction
        await tonConnectUI
            .sendTransaction(transaction)
            .then((response) => {
              if (response.status !== 200) {
                throw new Error("Failed to save the order");
              }
              toast.success("تراکنش با موفقیت انجام شد.");
              const transactionId = response.id;
              return axios.put("/api/orders", {
                _id: orderId,
                mainWallet: mainWallet,
                transactionId: transactionId,
                status: 1,
              });
            })
            .catch((error) => {
              toast.error(`خطا: ${error.message}`);
              console.error("Transaction or API error:", error);
            });
      } else {
        // New code for sending Jettons (like DOGS)
          const jettonWalletContract = Address.parse("EQAJ8uWd7EBqsmpSWaRdf_I-8R8-XHwh3gsNKhy-UrdrPcUo"); // Replace with the specific token's wallet address

          // Constructing the body (example) for the jetton transfer
          const body = beginCell()
              .storeUint(0xf8a7ea5, 32) // Operation code for transfer
              .storeUint(0, 64) // Query ID
              .storeCoins(toNano("100")) // Deposit amount
              .storeAddress(Address.parse(mainWallet)) // Replace with recipient address
              .storeAddress(Address.parse(mainWallet)) // Replace with the exceed fee receiver address
              .storeMaybeRef(null) // No custom payload
              .storeCoins(toNano("0.2")) // Forward TON amount
              .storeMaybeRef(beginCell().storeStringTail("Comment or forward payload if needed").endCell()) // Optional payload if needed
              .endCell();

          // Transaction payload
          const myTransaction = {
              validUntil: Math.floor(Date.now() / 1000) + 360, // Expiry time
              messages: [
                  {
                      address: jettonWalletContract.toString(true, true, true), // Sender's jetton wallet address
                      amount: toNano("0.2").toString(), // Commission fees
                      payload: body.toBoc().toString("base64"), // Payload with the transfer data
                  },
              ],
          };


          await tonConnectUI
              .sendTransaction(myTransaction)
              .then((response) => {
                  if (response.status !== 200) {
                      throw new Error("Failed to save the order");
                  }
                  toast.success("تراکنش با موفقیت انجام شد.");
                  const transactionId = response.id;
                  return axios.put("/api/orders", {
                      _id: orderId,
                      mainWallet: mainWallet,
                      transactionId: transactionId,
                      status: 1,
                  });
              })
              .catch((error) => {
                  toast.error(`خطا: ${error.message}`);
                  console.error("Transaction or API error:", error);
              });

          console.log('adawd',myTransaction)


          // Parameters for Jetton transfer
          // const jettonTransferParams = {
          //     validUntil: Math.floor(Date.now() / 1000) + 60, // Transaction valid for 60 seconds
          //     messages: [
          //         {
          //             address: jettonWalletAddress,
          //             amount: '0', // Amount to cover gas fees, 0 for Jettons
          //             payload: {
          //                 abi: {
          //                     type: 'Transfer',
          //                     params: {
          //                         to: recipientAddress,
          //                         amount: '100000000', // Amount in smallest units (adjust decimals accordingly)
          //                         forward_amount: '0', // Forward TON amount to cover fees
          //                         forward_payload: '',
          //                     },
          //                 },
          //             },
          //         },
          //     ],
          // };
          //
          // try {
          //     // Sending the transaction using TonConnect UI
          //     const result = await tonConnectUI.sendTransaction(jettonTransferParams);
          //     console.log('Jetton transfer result:', result);
          //     alert('Jetton transfer successful!');
          // } catch (error) {
          //     console.error('Jetton transfer failed:', error);
          //     alert('Jetton transfer failed. Check console logs for details.');
          // }
      };
    } catch (error) {
      toast.error(`${error.message}`);
    }
  };

  // Handler for confirm button click
  const handleConfirmClick = () => {
    if (!isChecked) {
      toast.error("لطفا شرایط و مقررات را قبول کنید.");
    } else {
      sendTransaction(); // Call sendTransaction when confirmed
    }
  };

  return (
      <div className={[styles.invoicedogs, className].join(" ")}>
        <section className={styles.dateContainerParent}>
          <div className={styles.dateContainer}>
            <div className={styles.imageContainerParent}>
              <div className={styles.imageContainer}>
                <div className={styles.div}>۱۲ تیر ۱۴۰۳، ۱۳:۵۰</div>
              </div>
              <img
                  onClick={onClose} // Call onClose when the image is clicked
                  className={styles.image3Icon}
                  loading="lazy"
                  alt=""
                  src={icon}
              />
              <div className={styles.wrapper}>
                <div className={styles.div1}>پیش فاکتور</div>
              </div>
            </div>
            <div className={styles.currencyType}>
              <div className={styles.dogs20000Wrapper}>
                <div className={styles.div}>
                <span>
                  <span>{symbol} </span>
                  <span className={styles.span}>{amount}</span>
                </span>
                </div>
              </div>
              <div className={styles.currencyPrice}>
                <div className={styles.currencyPriceValue} />
              </div>
              <div className={styles.div2}>تعداد ارز برای فروش</div>
            </div>
          </div>
          <div className={styles.currencyPriceLabel}>
            <div className={styles.totalAmount}>
              <div className={styles.div3}>
                <span>{rialPrice} </span>
                <span className={styles.span3}>ریال </span>
              </div>
            </div>
            <div className={styles.totalAmountLabelWrapper}>
              <div className={styles.currencyPriceValue} />
            </div>
            <div className={styles.div4}>قیمت هر واحد ارز</div>
          </div>
          <div className={styles.termsAgreement}>
            <div className={styles.agreementCheckbox}>
              <div className={styles.totalAmount}>
                <div className={styles.div5}>
                  <span>{amount * rialPrice} </span>
                  <span className={styles.span3}>ریال</span>
                </div>
              </div>
              <div className={styles.totalAmountLabelWrapper}>
                <div className={styles.currencyPriceValue} />
              </div>
              <div className={styles.parent}>
                <div className={styles.div6}>مجموع مبلغ</div>
                <div className={styles.div7}>توضیحات:</div>
              </div>
            </div>
            <div className={styles.div8}>
              در صورت تایید مراحل و پیش فاکتور، مراحل انتقال و واریز به حساب بانکی
              ( کارت ، شبا ) انجام میشود. نتیجه تراکنش و انتقال مبلغ را میتوانید
              در تاریخچه فروش ها مشاهده کنید.
            </div>
          </div>
          <div className={styles.pricePerUnitLabel}>
            <label>
              <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
              />
              <span className={styles.div9}>شرایط و مقررات را مطالعه و قبول دارم</span>
            </label>
          </div>
          <button
              className={styles.buttonconfirm}
              onClick={handleConfirmClick}
          >
            <div className={styles.glass} />
            <b className={styles.b}>تایید</b>
          </button>
        </section>
        <div className={styles.buttonBack}>
          <img
              onClick={onClose} // Call onClose when the image is clicked
              className={styles.xmarkIcon}
              alt=""
              src="/xmark.svg"
          />
        </div>
      </div>
  );
};

Invoice.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
};

export default Invoice;
