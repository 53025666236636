import PropTypes from "prop-types";
import styles from "./ActionButtons.module.css";

const ActionButtons = ({ className = "" }) => {
  return (
    <section className={[styles.actionButtons, className].join(" ")}>
      <button className={styles.buttonsend}>
        <b className={styles.b}>۱ شانس</b>
        <b className={styles.b1}>بچرخون</b>
      </button>
      <button className={styles.buttonsend1}>
        <b className={styles.b2}>بستن</b>
      </button>
    </section>
  );
};

ActionButtons.propTypes = {
  className: PropTypes.string,
};

export default ActionButtons;
