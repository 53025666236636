import FrameComponent12 from "../components/FrameComponent12";
import Press from "../components/Press";
import styles from "./AboutUS.module.css";
import MainNavigation from "../components/MainNavigation";

const AboutUS = () => {
    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(() => {
            window.history.back();
        });
    }
    return (
        <div className={styles.aboutUs}>
            <div className={styles.aboutUsInner}>
                <div onClick={() => window.history.back()}
                     className={styles.buttonCircleParent}>
                    <button className={styles.buttonCircle}>
                        <img
                            className={styles.hiconOutlineWebsite}
                            alt=""
                            src="/hicon--outline--website@2x.png"
                        />
                        <div className={styles.wrapper}>
                            <div className={styles.div}>وبسایت</div>
                        </div>
                    </button>
                    <div className={styles.frameWrapper}>
                        <div className={styles.frameParent}>
                            <div className={styles.container}>
                                <h3 className={styles.h3}>درباره ما</h3>
                            </div>
                            <div className={styles.buttonBack}>
                                <img
                                    className={styles.fiRrAngleSmallRightIcon}
                                    loading="lazy"
                                    alt=""
                                    src="/firranglesmallright.svg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <main className={`${styles.conteinerParent} allow-touch`}>
                <section className={styles.conteiner}>
                    <div className={styles.conteinerInner}>
                        <div className={styles.parent}>
                            <div className={styles.div1}>ماموریت و چشم انداز ها</div>
                            <div className={styles.div2}>
                                لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با
                                استفاده از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله
                                در ستون و سطرآنچنان که لازم است.
                            </div>
                            <div className={styles.div3}>
                <span
                    className={styles.span}
                >{`کتابهای زیادی در شصت و سه درصد گذشته، حال و آینده شناخت فراوان جامعه و متخصصان بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد.  `}</span>
                                <b className={styles.b}>بیشتر</b>
                            </div>
                        </div>
                    </div>
                    <FrameComponent12/>
                    <Press/>

                </section>
                <MainNavigation/>
            </main>
        </div>
    );
};

export default AboutUS;
