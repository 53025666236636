import PropTypes from "prop-types";
import styles from "./FrameComponent12.module.css";

const FrameComponent12 = ({ className = "" }) => {
  return (
    <div className={[styles.lightBgParent, className].join(" ")}>
      <img className={styles.lightBgIcon} alt="" src="/lightbg2.svg" />
      <div className={styles.founder}>
        <div className={styles.titleAndNavigation}>
          <div className={styles.rewards}>
            <div className={styles.getPoint}>
              <img
                className={styles.iconcoinpoint}
                loading="lazy"
                alt=""
                src="/iconcoinpoint1.svg"
              />
              <div className={styles.div}>+۱۵</div>
            </div>
            <div className={styles.followBtn}>
              <div className={styles.div1}>دنبال کن</div>
              <img
                className={styles.iconsplusSmall}
                loading="lazy"
                alt=""
                src="/iconsplussmall.svg"
              />
            </div>
          </div>
          <b className={styles.b}>مدیر عامل دکتر ارز</b>
          <img
            className={styles.iconsplusSmall}
            loading="lazy"
            alt=""
            src="/iconprofile3.svg"
          />
        </div>
        <div className={styles.div2}>
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده
          از طراحان گرافیک است. چاپگرها و متون بلکه روزنامه و مجله در ستون و
          سطرآنچنان که لازم است.
        </div>
        <div className={styles.cvCard}>
          <div className={styles.div3}>
            <div className={styles.list}>
              <div className={styles.title}>
                <h3 className={styles.h3}>فرشاد شمشاد</h3>
                <div className={styles.farshadShemshad}>Farshad Shemshad</div>
              </div>
              <div className={styles.list1}>
                <div className={styles.uiux}>
                  <ul className={styles.ul}>
                    <li>+۱۵ سال سابقه طراحی UI/UX</li>
                  </ul>
                </div>
                <div className={styles.uiux}>
                  <ul className={styles.ul}>
                    <li>مدیر و موسس دکتر ارز</li>
                  </ul>
                </div>
                <div className={styles.uiux}>
                  <ul className={styles.ul}>
                    <li>صاحب امتیاز توکن دکی تون</li>
                  </ul>
                </div>
                <div className={styles.uiux}>
                  <ul className={styles.ul}>
                    <li>مدیر ارشد جامعه تون فارسی</li>
                  </ul>
                </div>
              </div>
            </div>
            <img
              className={styles.miladPahlavanianIcon}
              loading="lazy"
              alt=""
              src="/milad-pahlavanian@2x.png"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FrameComponent12.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent12;
