import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';
import PropTypes from 'prop-types';
import axios from 'axios';
import styles from './FrameComponent3.module.css';

const FrameComponent3 = ({ className = '' , userId}) => {
    const [images, setImages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const maxNumber = 1;

    const onChange = async (imageList) => {
        // data for submit
        setImages(imageList);
        if (imageList.length > 0) {
            // Start loading
            setIsLoading(true);
            try {
                await uploadImage(imageList[0].file); // Call upload function
            } catch (error) {
                console.error('Image upload failed', error);
            }
            setIsLoading(false); // Stop loading
        }
    };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append('image', file);

        console.log('fileeee',formData)

        // try {
            const response = await axios.put('/api/users', {
                method: 'POST',
                body: formData,
                userId: userId
            });
            console.log('resupload',response)
            if (!response.status === 200) {
                throw new Error('Failed to upload image');
            }
            const result = await response.json();
            console.log('Image uploaded successfully:', result);
        // } catch (error) {
        //     console.error('Error uploading image:', error);
        //     throw error;
        // }
    };

    return (
        <div className={[styles.vectorParent, className].join(' ')}>
            <img className={styles.frameChild} loading="lazy" alt="" src="/line-1.svg" />
            <div className={styles.frameWrapper}>
                <div className={styles.backcardParent}>
                    <ImageUploading
                        multiple={false}
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        acceptType={['jpg', 'png', 'jpeg']}
                    >
                        {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                          }) => (
                            <>
                                <div className={styles.backcard}>
                                    <div className={styles.head}>
                                        <div className={styles.div}>انتخاب تصویر</div>
                                        <img className={styles.dangerTriangleIcon} alt="" src="/danger-triangle-1.svg" />
                                    </div>
                                    <div className={styles.selectfile}>
                                        <div className="upload__image-wrapper">
                                            <div
                                                className={styles.website}
                                                style={isDragging ? { color: 'red' } : null}
                                                onClick={onImageUpload}
                                                {...dragProps}
                                            >
                                                <b className={styles.b}>انتخاب</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.frameParent}>
                                    <div className={styles.parent}>
                                        <div className={styles.div1}>تصویر روی کارت ملی</div>
                                        <div className={styles.checkCircle1Wrapper}>
                                            <img className={styles.checkCircle1Icon} alt="" src="/checkcircle-1-2.svg" />
                                        </div>
                                    </div>
                                    <div className={styles.inputtext}>
                                        <div className={styles.inputtextInner}>
                                            <div className={styles.group}>
                                                <div className={styles.div2}>انتخاب فایل</div>
                                                <div className={styles.imageContainer}>
                                                    {isLoading ? (
                                                        <div className={styles.loadingSpinner}>در حال بارگذاری...</div>
                                                    ) : (
                                                        <img
                                                            className={styles.image1Icon}
                                                            alt=""
                                                            src={images.length ? images[0].data_url : '/image-1@2x.png'}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {imageList.length > 0 && (
                                            <div className={styles.website1}>
                                                <img
                                                    className={styles.fiRrTrashIcon}
                                                    loading="lazy"
                                                    alt=""
                                                    src="/firrtrash.svg"
                                                    onClick={onImageRemoveAll}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </ImageUploading>
                </div>
            </div>
        </div>
    );
};

FrameComponent3.propTypes = {
    className: PropTypes.string,
};

export default FrameComponent3;
