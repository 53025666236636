import {useState, useCallback} from "react";
import axios from 'axios';
import Invoice from "./Invoice";
import PortalPopup from "./PortalPopup";
import PropTypes from "prop-types";
import styles from "./MarketBox.module.css";
import { toast } from 'react-toastify';

const MarketBox = ({className = "", price, rialPrice, symbol, name, icon, balance, wallet, color}) => {
    const userId = localStorage.getItem("userId");
    const [isInvoiceOpen, setInvoiceOpen] = useState(false);
    const [inputValue, setInputValue] = useState(1); // Default input value
    const [order, setOrder] = useState({}); // Default input value

    const openInvoice = useCallback(async () => {

        if (inputValue > 0) {

            const orderData = {
                userId: userId,
                symbol: symbol,
                wallet: wallet,
                mainWallet: '',
                amount: inputValue,
                type: 'Sell',
                price: inputValue * rialPrice,
                fee: 0, // Set appropriate fee
                unitRialPrice: rialPrice,
                unitDollarPrice: price, // Use actual dollar price if available
                status: 0, // Set appropriate status
            };

            try {
                // Make API call to save order data
                const response = await axios.post('/api/orders', orderData);

                if (response.data._id) {
                    console.log('Order', response.data._id)
                    setOrder(response.data)
                    console.log('Order saved successfully');
                }
            } catch (error) {
                console.error('Error saving order:', error);
            }

            setInvoiceOpen(true);
        } else {
            toast.error("مقدار فروش را انتخاب کنید!");
        }
    }, []);

    const closeInvoice = useCallback(() => {
        setInvoiceOpen(false);
    }, []);

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    return (
        <>
            <div className={[styles.div, className].join(" ")}>
                <h3 className={styles.dogs}> برای فروش، موجودی {balance} {symbol} تعداد </h3>
                <div className={styles.inputParent}>
                    <div className={styles.input}>
                        <div className={styles.inputLabel}>
                            <h3 className={styles.dogs1}>{symbol}</h3>
                        </div>
                        <input
                            type="number"
                            className={styles.inputValue}
                            value={inputValue}
                            onChange={handleInputChange}
                        />
                        {/*<div className={styles.inputValue}>602,000,000</div>*/}
                        <div className={styles.logo}>
                            <img
                                className={styles.image5Icon}
                                loading="lazy"
                                alt=""
                                src={icon}
                            />
                        </div>
                    </div>
                    <div className={styles.input1}>
                        <div className={styles.wrapper}>
                            <h3 className={styles.h3}>ریال</h3>
                        </div>
                        <h2 className={styles.h2}>{Math.round(inputValue * price * rialPrice).toLocaleString('en-US')}</h2>
                        <div className={styles.iconsrialWrapper}>
                            <img
                                className={styles.iconsrial}
                                loading="lazy"
                                alt=""
                                src="/iconsrial.svg"
                            />
                        </div>
                    </div>
                </div>
                <button className={styles.buttonconfirm} onClick={openInvoice}>
                    <div className={styles.glass}/>
                    <b className={styles.b}>تایید</b>
                </button>
            </div>
            {isInvoiceOpen && (
                <PortalPopup
                    overlayColor="rgba(113, 113, 113, 0.3)"
                    placement="Centered"
                    onOutsideClick={closeInvoice}
                    price={price}
                    rialPrice={rialPrice}
                    symbol={symbol}
                    name={name}
                    icon={icon}
                >
                    <Invoice
                        onClose={closeInvoice}
                        price={price}
                        rialPrice={rialPrice}
                        symbol={symbol}
                        name={name}
                        icon={icon}
                        amount={inputValue}
                        orderId={order._id}
                    />
                </PortalPopup>
            )}
        </>
    );
};

MarketBox.propTypes = {
    className: PropTypes.string,
};

export default MarketBox;
