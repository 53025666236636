import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import Topnavigation from "../components/Topnavigation";
import MarketBox from "../components/MarketBox";
import FrameComponent9 from "../components/FrameComponent9";
import styles from "./Market.module.css";
import Loading from "../components/Loading";

const Market = () => {
    const userId = localStorage.getItem("userId");
    const location = useLocation();
    const [market, setMarket] = useState(''); // Default symbol
    const [price, setPrice] = useState(null);
    const [rialPrice, setRialPrice] = useState(null);
    const { symbol = '', name = '', icon = '' } = location.state || {}; // Provide default values
    const [loading, setLoading] = useState(true);
    const [wallet, setWallet] = useState('');
    const [balance, setBalance] = useState(0);

    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(() => {
            window.history.back();
        });
    }

    const fetchPrice = async () => {
        try {
            const { data } = await axios.post('/api/price');
            setRialPrice(data.latest);
        } catch (err) {
            console.error('Failed to fetch user details', err);
        }
    };

    const fetchBalance = async (wallet) => {
        try {
            const response = await axios.get(`https://toncenter.com/api/v2/getAddressBalance?address=${wallet}`);
            console.log('res', response);
            if (response.data.ok) {
                setBalance(response.data.result / Math.pow(10, 9)); // Convert nanograms to TON
            } else {
                console.error("Unexpected response format:", response.data);
            }
        } catch (error) {
            console.error("Error fetching balance:", error);
        }
    };

    useEffect(() => {
        setMarket(symbol.toLowerCase() + "usdt");
        fetchPrice();

        const fetchUserData = async () => {
            try {
                const response = await axios.get(`/api/users/${userId}`);
                if (response.data) {
                    setWallet(response.data.wallet);
                    fetchBalance(response.data.wallet);
                }

                const resAvatar = await axios.get(`/api/users/avatar/${userId}`, {
                    responseType: 'blob',
                });
                if (resAvatar.data) {
                    const objectUrl = URL.createObjectURL(resAvatar.data);
                    setAvatar(objectUrl);
                }
            } catch (error) {
                console.error('Failed to fetch user data', error);
            } finally {
                setLoading(false);
            }

            const socket = new WebSocket(`wss://drarzserver.sarafiton.com:5001?symbol=${market}`);

            socket.onmessage = async (event) => {
                try {
                    let dataString;

                    if (typeof event.data === 'string') {
                        dataString = event.data;
                    } else if (event.data instanceof ArrayBuffer) {
                        const decoder = new TextDecoder('utf-8');
                        dataString = decoder.decode(new Uint8Array(event.data));
                    } else if (event.data instanceof Blob) {
                        dataString = await new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.onload = () => resolve(reader.result);
                            reader.onerror = () => reject(new Error('FileReader error'));
                            reader.readAsText(event.data);
                        });
                    } else {
                        throw new Error('Unexpected data type');
                    }

                    const parsedData = JSON.parse(dataString);
                    if (parsedData.p) {
                        const formattedPrice = parseFloat(parsedData.p).toFixed(8);
                        setPrice(formattedPrice);
                    }
                } catch (error) {
                    console.error('Failed to parse WebSocket message:', error);
                    console.log('Raw data:', event.data);
                }
            };

            socket.onerror = (error) => {
                console.error('WebSocket error:', error);
            };

            return () => {
                socket.close();
            };
        };

        fetchUserData();
    }, [market, symbol, userId]);

    return (
        <div className={styles.marketBuydogs}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    <img className={styles.starsIcon} alt="" src="/stars2@2x.png" />
                    <div className={styles.topnavigationParent}>
                        <Topnavigation price={price} rialPrice={rialPrice} symbol={symbol} name={name} icon={icon} balance={balance} wallet={wallet} />
                        <div className={styles.conteiner}>
                            <MarketBox price={price} rialPrice={rialPrice} symbol={symbol} name={name} icon={icon} balance={balance} wallet={wallet} />
                            <FrameComponent9 symbol={symbol} />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Market;
