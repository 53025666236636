import { useCallback, useEffect, useState } from "react";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import RankItemDetails from "../components/RankItemDetails";
import SpinAnimation from "../components/SpinAnimation";
import styles from "./Ranking.module.css";
import MainNavigation from "../components/MainNavigation";
import Loading from '../components/Loading';

const Ranking = () => {
    const navigate = useNavigate();
    const [leaders, setLeaders] = useState([]);
    const [loading, setLoading] = useState(true); // To show the loading animation

    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(() => {
            window.history.back();
        });
    }

    const onButtonBackContainerClick = useCallback(() => {
        navigate("/home");
    }, [navigate]);

    useEffect(() => {
        const fetchLeaders = async () => {
            try {
                const { data } = await axios.get('/api/users/leaderboard');
                const usersWithAvatars = [];

                for (const user of data) {
                    try {
                        const resAvatar = await axios.get(`/api/users/avatar/${user.userId}`, {
                            responseType: 'blob',
                        });
                        if (resAvatar.data) {
                            const objectUrl = URL.createObjectURL(resAvatar.data);
                            usersWithAvatars.push({ ...user, avatar: objectUrl });
                        } else {
                            usersWithAvatars.push(user); // Add the user without an avatar if no avatar data is available
                        }
                    } catch (err) {
                        console.warn("Avatar error", err);
                        usersWithAvatars.push(user); // Add the user without an avatar in case of error
                    }
                }

                // Remove the first three users from the list
                const filteredUsers = usersWithAvatars.slice(3);
                setLeaders(filteredUsers);
            } catch (error) {
                console.error('Error fetching leaderboard data', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLeaders();
    }, []);


    return (
        <div className={styles.ranking}>
            <img className={styles.starsIcon} alt="" src="/Stars.png"/>
            <div className={styles.content}>
                <div onClick={() => window.history.back()}
                     className={styles.rankingListContainer}>
                    <div className={styles.rankingListHeader}>
                        <div className={styles.lastUpdateContainer}>
                            {/*<div className={styles.rankingListHeader}>*/}
                            {/*    <img*/}
                            {/*        className={styles.iconsrefresh}*/}
                            {/*        loading="lazy"*/}
                            {/*        alt=""*/}
                            {/*        src="/iconsrefresh.svg"*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div className={styles.div}>آخرین بروزرسانی ۲۰:۴۱</div>*/}
                        </div>
                    </div>
                    <div className={styles.rankingTitleContainer}>
                        <div className={styles.rankingTitleRow}>
                            <b className={styles.b}>برترین ها</b>
                        </div>
                        <div
                            className={styles.buttonBack}
                            onClick={onButtonBackContainerClick}
                        >
                            <img
                                className={styles.fiRrAngleSmallRightIcon}
                                loading="lazy"
                                alt=""
                                src="/firranglesmallright.svg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.listContainer}>
                <div className={styles.conteiner}>
                    <RankItemDetails/>
                    <div className={`${styles.rank410} allow-touch`}>
                        {loading ? (
                            <Loading />
                        ) : (
                            leaders.map((leader, index) => (
                        <div className={styles.rankinglist}>
                            <div className={styles.rank}>
                                <img
                                    className={styles.fiRrCaretUpIcon}
                                    loading="lazy"
                                    alt=""
                                    src="/firrcaretup-1.svg"
                                />
                                <div className={styles.div1}>{index + 4}</div>
                            </div>
                            <div className={styles.idpoint}>
                                <div className={styles.point}>
                                    <img
                                        className={styles.iconcoinpoint}
                                        loading="lazy"
                                        alt=""
                                        src="/coin-dollar.svg"
                                    />
                                    <div className={styles.div2}>{leader.coins}</div>
                                </div>
                                <div className={styles.pooriyabisun}>{leader.first_name}</div>
                            </div>
                            <img
                                className={styles.avatarIcon}
                                loading="lazy"
                                alt=""
                                src={leader.avatar ? leader.avatar : "/avatar-31@2x.png"}
                            />
                        </div>
                            ))
                        )}
                    </div>
                    <SpinAnimation/>
                </div>
                <MainNavigation/>
            </div>
        </div>
    );
};

export default Ranking;
