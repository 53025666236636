import PropTypes from "prop-types";
import styles from "./LogoDrArz.module.css";

const LogoDrArz = ({ className = "" }) => {
  return (
    <div className={[styles.logoDrArz, className].join(" ")}>
      <img
        className={styles.vectorIcon}
        loading="lazy"
        alt=""
        src="/vector.svg"
      />
      <div className={styles.drarznet}>DrArz.net</div>
    </div>
  );
};

LogoDrArz.propTypes = {
  className: PropTypes.string,
};

export default LogoDrArz;
