import PropTypes from "prop-types";
import styles from "./TaskComponent.module.css";
import { toast } from 'react-toastify';
import React, {useState} from 'react';


const TaskComponent = ({ className = "", referralLink, referralCode }) => {
  const [isCopied, setIsCopied] = useState(false);
  const copyToClipboard = () => {
    navigator.clipboard.writeText(referralLink);
    setIsCopied(true);
    toast.success('لینک معرفی کپی شد.');
  };

  return (
      <div className={[styles.referralLinkParent, className].join(" ")}>
        <div className={styles.referralLink}>
          <div className={styles.head}>
            <button className={styles.rewards}>
              <div className={styles.addFriend}>
                <div className={styles.div}>دعوت از دوستان</div>
                <img
                    className={styles.iconsplusSmall}
                    alt=""
                    src="/iconsplussmall1.svg"
                />
              </div>
            </button>
            <h3 className={styles.h3}>دریافت پاداش گروهی</h3>
            <img
                className={styles.iconsplusSmall}
                loading="lazy"
                alt=""
                src="/iconsuseradd.svg"
            />
          </div>
          <div className={styles.link}>
            <div className={styles.httpstmedoctorarz5505350}>
            <span className={styles.httpstmedoctorarz}>
              https://t.me/doctorarz/
            </span>
              <b>{referralCode}</b>
            </div>
            <div className={styles.rewards1}>
              <div className={styles.copy}>
                <input type="hidden" value={referralLink} readOnly/>
                <div onClick={copyToClipboard} className={styles.div1}>
                  کپی
                  <input className={styles.iconsclone} type="checkbox"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.div2}>
            پاداش اختصاصی برای کسانی که بیشتر معرفی کرده اند.
          </div>
        </div>
        <div className={styles.desc}>
            <span
                className={styles.span}
            >برای شما عزیزانی که به ما کمک کرده اید تا سریع تر به اهداف خود برسیم </span>
          <b> پاداش های بیشتری در نظر داریم </b>
          <span
              className={styles.span}
          >که در لیست زیر قابل دریافت هستند.</span>
          <b>شما و کاربر معرفی شده هر یک ۱ امتیاز هدیه</b>
          <span className={styles.span}> دریافت خواهید کرد.</span>
        </div>
      </div>
  );
};

TaskComponent.propTypes = {
  className: PropTypes.string,
};

export default TaskComponent;
