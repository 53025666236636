import PropTypes from "prop-types";
import styles from "./List.module.css";

const List = ({ className = "" }) => {
  return (
    <div className={[styles.list, className].join(" ")}>
      <div className={styles.chatactive}>
        <div className={styles.head}>
          <div className={styles.div}>۱۵:۳۸</div>
          <div className={styles.div1}>پیگیری فروش همستر</div>
          <img
            className={styles.avatarIcon}
            loading="lazy"
            alt=""
            src="/avatar@2x.png"
          />
        </div>
        <div className={styles.div2}>
          من دیروز درخواست فروش خودم رو ثبت کردم، اما همچنان در وضعیت در دست
          بررسی است . چرا انجام نشده؟
        </div>
        <div className={styles.tag}>
          <div className={styles.div3}>در حال بررسی</div>
        </div>
      </div>
      <div className={styles.chatbot}>
        <div className={styles.head}>
          <div className={styles.div}>۱۵:۳۸</div>
          <div className={styles.div1}>
            ادامه گفتگو با دستیار هوشمند دکتر.ارز
          </div>
          <img
            className={styles.avatarIcon1}
            loading="lazy"
            alt=""
            src="/avatar-1.svg"
          />
        </div>
      </div>
      <div className={styles.chatlabel}>
        <div className={styles.head}>
          <div className={styles.tag1}>
            <div className={styles.div6}>مهم</div>
          </div>
          <div className={styles.div7}>دیروز</div>
          <div className={styles.div1}>مدارک شما تایید نشد</div>
          <img className={styles.avatarIcon1} alt="" />
        </div>
        <div className={styles.div9}>
          مدارک ارسالی ( کارت ملی ) خوانا نیست، لطفا مجدد ارسال کنید
        </div>
      </div>
      <div className={styles.chatlast}>
        <div className={styles.head}>
          <div className={styles.chatItemTitle}>
            <div className={styles.div10}>اطلاع رسانی</div>
          </div>
          <div className={styles.div11}>۲۲ تیر ۱۴۰۳</div>
          <div className={styles.div12}>آدرس کیف پول</div>
          <img
            className={styles.avatarIcon1}
            loading="lazy"
            alt=""
            src="/avatar-3.svg"
          />
        </div>
        <div className={styles.div13}>
          کیف پول شما معتبر نیست و در تحریم شده است
        </div>
      </div>
      <div className={styles.chatclose}>
        <div className={styles.head}>
          <div className={styles.wrapper}>
            <div className={styles.div14}>بسته شده</div>
          </div>
          <div className={styles.div15}>۲۰ تیر ۱۴۰۳</div>
          <div className={styles.div16}>لطفا راهنمایی کنید</div>
          <img
            className={styles.avatarIcon}
            loading="lazy"
            alt=""
            src="/avatar@2x.png"
          />
        </div>
        <div className={styles.div17}>
          من دیروز درخواست فروش خودم رو ثبت کردم، اما همچنان در وضعیت در دست
          بررسی است . چرا انجام نشده؟
        </div>
      </div>
    </div>
  );
};

List.propTypes = {
  className: PropTypes.string,
};

export default List;
