import PropTypes from "prop-types";
import styles from "./ChatInputContainer.module.css";

const ChatInputContainer = ({ className = "" }) => {
  return (
    <div className={[styles.chatInputContainer, className].join(" ")}>
      <div className={styles.chatInput}>
        <button className={styles.buttonsend}>
          <img
            className={styles.chatbotSpeechBubble1Icon}
            alt=""
            src="/chatbotspeechbubble-1.svg"
          />
          <div className={styles.div}>دستیار هوش مصنوعی</div>
        </button>
        <button className={styles.buttonsend1}>
          <div className={styles.div1}>درخواست جدید پشتیبانی</div>
        </button>
      </div>
      <div className={styles.newChatContainer}>
        <div className={styles.buttonCircle}>
          <img
            className={styles.fiRrAngleSmallDownIcon}
            alt=""
            src="/firranglesmalldown.svg"
          />
          <div className={styles.wrapper}>
            <div className={styles.div2}>جدیدترین</div>
          </div>
        </div>
        <div className={styles.chatCountContainer}>
          <div className={styles.chatCountInnerContainer}>
            <div className={styles.chatCount}>
              <b className={styles.b}>۶</b>
            </div>
            <div className={styles.container}>
              <div className={styles.div3}>درخواست های پیشتیبانی</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ChatInputContainer.propTypes = {
  className: PropTypes.string,
};

export default ChatInputContainer;
