import PropTypes from "prop-types";
import styles from "./FrameComponent7.module.css";

const FrameComponent7 = ({ className = "" }) => {
  return (
    <div className={[styles.marketTopParent, className].join(" ")}>
      <img
        className={styles.topnavigationIcon}
        alt=""
        src="/topnavigation.svg"
      />
      <div className={styles.marketTitle}>
        <b className={styles.b}>{`بازار ارز ها `}</b>
      </div>
      <div className={styles.marketTitle1}>
        <div className={styles.div}>ارز ها و توکن های قابل خرید موجود</div>
      </div>
    </div>
  );
};

FrameComponent7.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent7;
