import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import FrameComponent6 from "../components/TaskComponent";
import MainNavigation from "../components/MainNavigation";
import styles from "./Task.module.css";
import {toast} from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import TaskComponent from "../components/TaskComponent";

const Task = () => {
    const userId = localStorage.getItem("userId");
    const [user, setUser] = useState({});
    const [referralLink, setReferralLink] = useState('');
    const [referralCode, setReferralCode] = useState('');
    const [referredUsers, setReferredUsers] = useState([]);
    const [referredCount, setReferredCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [coins, setCoins] = useState(0);
    const [level, setLevel] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const [tasks, setTasks] = useState([]);
    const [selectedTab, setSelectedTab] = useState('Ref');
    const navigate = useNavigate();

    if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.BackButton.show();
        window.Telegram.WebApp.BackButton.onClick(() => {
            window.history.back();
        });
    }

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const {data} = await axios.get(`/api/users/${userId}`);
                setUser(data);
                setReferralCode(data.referralCode);
                setReferralLink(`https://t.me/doctorarz_bot?start=${data.referralCode}`);
                await fetchReferralData(data.referralCode, 1);
            } catch (err) {
                console.error('Failed to fetch user details', err);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, [userId]);

    useEffect(() => {
        const fetchTasks = async () => {
            try {
                const refTasks = await axios.get(`/api/tasks/Ref?userId=${userId}`);
                setTasks(refTasks.data);
                console.log('taskkkkk',refTasks.data)
            } catch (err) {
                console.error('Failed to fetch tasks', err);
            } finally {
                setLoading(false);
            }
        };

        fetchTasks();
    }, []);

    const fetchReferralData = async (referralCode) => {
        try {
            const { data } = await axios.get(`/api/referrals/${referralCode}`);
            setReferredCount(data.count);
        } catch (err) {
            toast.error('Failed to fetch referral details');
        }
    };

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
    };

    const formatNumber = (number) => {
        if (number >= 1000000000) return `${(number / 1000000000).toFixed(1)}B`;
        if (number >= 1000000) return `${(number / 1000000).toFixed(1)}M`;
        if (number >= 1000) return `${(number / 1000).toFixed(1)}K`;
        return `${number}`;
    };

    const handleTaskClick = (task, tab) => {
        if (tab === 'Cinema' || tab === 'Special') {
            navigate(`/tasks/${task._id}`);
        }
    };

    const handleClaim = async (task, tab) => {
        try {
            const response = await axios.post('/api/tasks/claim', {userId, taskId: task._id, tab, referredCount});
            if (response.status === 200) {
                const {coins, referredCount} = response.data.user;
                setUser({...user, coins, referredCount});
                setTasks(prevTasks =>
                    prevTasks.map(t => t._id === task._id ? { ...t, userTask: { ...t.userTask, status: 'completed' } } : t)
                );
                toast.success("امتیاز این تسک دریافت شد.")
            } else {
                toast.error("خطایی هنگام دریافت امتیاز رخ داده!")
            }
        } catch (err) {
            toast.error("شما قبلا امتیاز این تسک را دریافت کرده اید!")
        }
    };

    return (
        <div className={styles.earn}>
            <div className={styles.watchVideoParent}>
                <div className={styles.frameWrapper}>
                    <div className={styles.frameParent}>
                        <div className={styles.frame}>
                            <h3 className={styles.h3}>تسک های من</h3>
                        </div>
                        <div className={styles.buttonBack}>
                            <img
                                className={styles.fiRrAngleSmallRightIcon}
                                loading="lazy"
                                alt=""
                                src="/firranglesmallright.svg"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <img className={styles.coverFrontIcon} alt="" src="/cover-front.svg"/>
            <div className={styles.conteinerParent}>
                <div className={styles.conteiner}>
                    <img className={styles.starsIcon} alt="" src="/stars@2x.png"/>
                    <TaskComponent referralCode={referralCode} referralLink={referralLink}/>
                    <div className={styles.frameGroup}>
                        <div className={styles.frameContainer}>
                            <div className={styles.buttonCircleParent}>
                                <div className={styles.referralsCountContainerWrapper}>
                                    <div className={styles.referralsCountContainer}>
                                        <div className={styles.numbers}>
                                            <div className={styles.div10}>{referredCount} نفر</div>
                                        </div>
                                        <div className={styles.div11}>معرفی شدگان</div>
                                        <div className={styles.iconsuserSalaryWrapper}>
                                            <img
                                                className={styles.iconsuserSalary}
                                                loading="lazy"
                                                alt=""
                                                src="/iconsusersalary.svg"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={styles.friendsList}>
                                {tasks.map(task => (
                                    <div className={styles.friendslist}>
                                        {!task.userTask && referredCount >= task.ref ? (
                                            <button
                                                className="TaskComponent_rewards__64U9n"
                                                onClick={() => handleClaim(task)}
                                            >
                                                <div className="TaskComponent_addFriend__ne1Ol">
                                                    <div className="TaskComponent_div__O0YbU">دریافت</div>
                                                    <img
                                                        className="TaskComponent_iconsplusSmall__m62Q8"
                                                        alt="claim reward"
                                                        src="/iconsplussmall1.svg"
                                                    />
                                                </div>
                                            </button>
                                        ) : (
                                            <button className="TaskComponent_rewards__64U9n" disabled>
                                                <div className="TaskComponent_addFriend__ne1Ol">
                                                    <div className="TaskComponent_div__O0YbU">✔</div>
                                                </div>
                                            </button>
                                        )}
                                        <div className={styles.nameDate}>
                                            <div className={styles.nameid}>
                                                <div className={styles.ehsanpahlv}>
                                                    <div className={styles.content2}>
                                                        <div className={styles.div12}>
                                                            <span className={styles.span5}>{`۶۰،۰۰۰ `}</span>
                                                            <span className={styles.span6}>تومان</span>
                                                        </div>
                                                        <div className={styles.pointDetails}>
                                                            <img
                                                                className={styles.iconcoinpoint2}
                                                                loading="lazy"
                                                                alt=""
                                                                src="/coin-dollar.svg"
                                                            />
                                                            <div
                                                                className={styles.div13}>{formatNumber(task.coins)} امتیاز
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.content2}>
                                                    <div className={styles.div12}>
                                                        <span className={styles.span5}>{task.title}</span>
                                                    </div>
                                                    <div className={styles.pointDetails}>
                                                        <img
                                                            className={styles.iconcoinpoint2}
                                                            loading="lazy"
                                                            alt=""
                                                            src="/coin-dollar.svg"
                                                        />
                                                        <div
                                                            className={styles.div13}>{formatNumber(task.coins)} امتیاز
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <img
                                            className={styles.avatarIcon}
                                            loading="lazy"
                                            src="/avatar-1.svg"
                                            alt=""
                                        />
                                    </div>
                                ))}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <MainNavigation/>
        </div>
    );
};

export default Task;
