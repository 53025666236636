import ActionButtons from "../components/ActionButtons";
import styles from "./SpinWheel.module.css";

const SpinWheel = () => {
  return (
    <div className={styles.spinwheel}>
      <main className={styles.chanceDescriptionParent}>
        <div className={styles.chanceDescription}>
          <h1 className={styles.h1}>چرخونه شانس</h1>
        </div>
        <div className={styles.chanceDescription1}>
          <div className={styles.div}>
            هروز شانس ۱ نوبت شانس این را دارید شانس خود را برای کسب جوایز و
            امتیاز امتحان کنید.
          </div>
        </div>
        <img className={styles.spinDrarzIcon} alt="" src="/spin-drarz.svg" />
        <ActionButtons />
      </main>
      <div className={styles.buttonBack}>
        <img className={styles.xmarkIcon} alt="" />
      </div>
    </div>
  );
};

export default SpinWheel;
