import Friendslist from "./Friendslist";
import PropTypes from "prop-types";
import styles from "./FrameComponent9.module.css";

const FrameComponent9 = ({ className = "", symbol }) => {
  return (
    <div className={[styles.stepsContainerParent, className].join(" ")}>
      <div className={styles.stepsContainer}>
        <div className={styles.parent}>
          <div className={styles.div}>مراحل فروش:</div>
          <div className={styles.walletContainer}>
            <ul className={styles.wallet}>
              <li className={styles.wallet1}>
                بعد از وارد نمودن تعداد مورد نظر جهت فروش و زدن دکمه تایید،
                فرآیند اتصال به کیف پول Wallet شما انجام و صورتحساب فروش به شما
                نمایش داده میشود .
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={`allow-touch ${styles.historyHeaderParent}`}>
        <div className={styles.transactions}>
          <Friendslist
            prop="۲،۳۶۰،۰۰۰ "
            tON="$TON"
            prop1="200."
            symbol={symbol}
          />
        </div>
      </div>
    </div>
  );
};

FrameComponent9.propTypes = {
  className: PropTypes.string,
};

export default FrameComponent9;
